import { Button } from '@finn/atoms';
import { Modal, ModalContent, ModalHeader } from '@finn/atoms/Modal';
import { CalendarFilled } from '@finn/design-system/icons/calendar-filled';
import { CarFilled } from '@finn/design-system/icons/car-filled';
import { DashboardFilled } from '@finn/design-system/icons/dashboard-filled';
import { BASE_SUBSCRIPTION_PATH } from '@finn/ua-constants';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { config, Locale, useCurrentLocale, useIsMobile } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useModalStyles } from './modalStyles';

type InfoRowProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
};

type Props = {
  open: boolean;
  onClose(): void;
};

const InfoRow: React.FC<InfoRowProps> = ({ Icon, title, description }) => {
  const classes = useModalStyles();

  return (
    <div className={classes.infoRow}>
      <Icon width="32" height="32" className={classes.infoIcon} />
      <div className="flex flex-col gap-2">
        <span className="body-16-semibold">{title}</span>{' '}
        <span className="body-16-regular">{description}</span>
      </div>
    </div>
  );
};

export const EmptyCartModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useModalStyles();
  const router = useRouter();
  const { locale } = useCurrentLocale();
  const i18n = useIntl();
  const isMobile = useIsMobile();
  const track = useTrackingStore((state) => state.track);

  const title = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.title',
  });

  const subtitle = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.subtitle',
  });
  const benefit1Title = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.benefit1Title',
  });
  const benefit2Title = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.benefit2Title',
  });
  const benefit3Title = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.benefit3Title',
  });
  const benefit1Description = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.benefit1Description',
  });
  const benefit2Description = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.benefit2Description',
  });
  const benefit3Description = i18n.formatMessage({
    id: 'checkout.returnToCheckoutEmpty.benefit3Description',
  });

  const onCTAClick = useCallback(() => {
    track(TrackingEventName.CTA_CLICKED, {
      location: 'empty-basket',
    });

    if (locale === Locale.ENGLISH_GERMANY) {
      router.push(
        `${config.FINN_WEB_URL}/${Locale.GERMAN_GERMANY}/${BASE_SUBSCRIPTION_PATH}`
      );
    }

    router.push(`/${locale}/${BASE_SUBSCRIPTION_PATH}`);
  }, [locale, router]);

  useEffect(() => {
    track(TrackingEventName.BASKET_VIEWED, {
      location: 'empty-basket',
    });
  }, []);

  return (
    <Modal size="xl" open={open} onClose={onClose} scrollAll>
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>
        <div className="bg-snow p-6">
          <div className={classes.textBlock}>
            <span className="body-16-semibold">{subtitle}</span>
            <InfoRow
              Icon={DashboardFilled}
              title={benefit1Title}
              description={benefit1Description}
            />
            <InfoRow
              Icon={CalendarFilled}
              title={benefit2Title}
              description={benefit2Description}
            />
            <InfoRow
              Icon={CarFilled}
              title={benefit3Title}
              description={benefit3Description}
            />
          </div>
          <div className={classes.ctaBlock}>
            <Button
              label={i18n.formatMessage({
                id: 'checkout.modals.carNotAvailable.cta',
              })}
              data-cy="empty-cart-cta"
              size="large"
              fullWidth={isMobile}
              onClick={onCTAClick}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
